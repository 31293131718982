var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-2 weekday pa-2 clickable",class:{
    'elevation-3':
      _vm.dayDt.toLocaleString() === _vm.selectedDate.toLocaleString() &&
      _vm.scope === _vm.$scopeMap.day,
  },attrs:{"color":(_vm.$vuetify.theme.dark &&
      _vm.dayDt.toLocaleString() === _vm.selectedDate.toLocaleString() &&
      _vm.scope === _vm.$scopeMap.day &&
      'grey darken-3') ||
      '',"outlined":_vm.dayDt.toLocaleString() === _vm.$DateTime.local().toLocaleString(),"flat":""},nativeOn:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.setDateAndScope({ date: _vm.dayDt })}}},[_c('div',{staticClass:"weekday__day font-weight-medium",on:{"click":function($event){return _vm.setDateAndScope({ date: _vm.dayDt })}}},[_c('span',[(_vm.dayDt.toLocaleString() === _vm.$DateTime.local().toLocaleString())?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v(" far fa-calendar-check ")]):_vm._e(),(_vm.dayDt.weekday === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.dayDt.toLocaleString({ weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.dayDt.toLocaleString({ weekday: 'long', day: 'numeric' }))+" ")])],1),_c('v-btn',{staticClass:"weekday__plus",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('addNewEntry', _vm.dayDt)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-plus")])],1)],1),(_vm.tasks.length && _vm.tasks.every(e => e.dateComplete))?_c('div',{staticClass:"text-caption grey--text d-inline-block"},[_vm._v(" "+_vm._s(_vm.$t('components.week.tasks-complete'))+" 🤩 ")]):_vm._e(),_c('EntryList',{attrs:{"entries":_vm.entries.filter(e => !e.dateComplete),"updateData":{
      ..._vm.getDTfromDateObject(_vm.getDateObject(_vm.dayDt), true),
    },"add-btn":false,"context":"date"},on:{"addNewEntry":function($event){return _vm.$emit('addNewEntry', _vm.dayDt)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }