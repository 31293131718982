<template>
  <div>
    <h2 class="mb-2">{{ $t('components.customize-colors.title') }}</h2>

    <v-switch
      :label="$t('components.customize-colors.dark-mode')"
      v-model="darkMode"
    />
    <v-switch
      :label="$t('components.customize-colors.minimal')"
      v-model="minimal"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    darkMode: {
      get() {
        return this.$store.state.app.darkMode
      },
      set(value) {
        this.setDarkMode({ vue: this, value })
      },
    },
    minimal: {
      get() {
        return this.$store.state.app.minimal
      },
      set(value) {
        this.SET_MINIMAL(value)
      },
    },
  },

  methods: {
    ...mapActions('app', ['setDarkMode']),
    ...mapMutations('app', ['SET_MINIMAL']),
  },
}
</script>
