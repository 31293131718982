<template>
  <v-container v-if="prompt" class="container--slim prompt js-prompt">
    <div class="d-flex align-top mt-12">
      <v-textarea
        ref="titleInput"
        v-model="text"
        auto-grow
        rows="1"
        solo
        flat
        background-color="transparent"
        class="text-h4 pa-0"
        dense
        :placeholder="$t('views.prompt.title')"
        @blur="updateTitle"
        @keypress.enter.exact="$event.target.blur()"
        hide-details
      ></v-textarea>

      <div>
        <v-btn
          @click="toggleActive"
          small
          outlined
          :class="{ translucent: prompt.archived }"
          class="mr-1"
        >
          {{ prompt.archived ? $t('app.archived') : $t('app.archive') }}
        </v-btn>

        <v-btn icon @click="deletePrompt">
          <v-icon small>fas fa-trash</v-icon>
        </v-btn>
      </div>
    </div>

    <div v-if="!entries.length" class="text-caption grey--text">
      {{ $t('views.prompt.tip') }}
    </div>

    <EntryList
      :entries="entries"
      :addBtn="false"
      context="prompt"
      :ordered="false"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import EntryList from '@/components/EntryList.vue'
import { EntriesDatabase } from '@/services/database'

export default {
  components: {
    EntryList,
  },

  data() {
    return {
      text: '',
      entries: [],
      entryObservable$: null,
    }
  },

  computed: {
    ...mapState('prompts', ['prompts']),

    promptId() {
      return this.$route.params.promptId
    },

    prompt() {
      return this.prompts.find(p => p.id === this.promptId)
    },
  },

  watch: {
    prompt: {
      handler: function(newVal) {
        if (newVal) {
          const text = newVal && newVal.text
          this.text = text
          this.$nextTick(() => {
            if (!text && this.$refs.titleInput) this.$refs.titleInput.focus()
          })
          this.getEntries(newVal.id)
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapActions('prompts', ['updatePrompt', 'destroyPrompt']),

    disposeListener() {
      if (this.entryObservable$) this.entryObservable$.unsubscribe()
    },

    getEntries(promptId) {
      this.disposeListener()

      this.entryObservable$ = EntriesDatabase.observePromptEntries$(
        promptId,
        data => {
          this.entries = data
        }
      )
    },

    updateTitle() {
      this.updatePrompt({
        promptId: this.promptId,
        data: {
          text: this.text,
        },
      })
    },

    toggleActive() {
      this.updatePrompt({
        promptId: this.promptId,
        data: {
          archived: !this.prompt.archived,
        },
      })
    },

    deletePrompt() {
      if (confirm(this.$t('views.prompt.delete-prompt'))) {
        this.destroyPrompt(this.promptId)
        this.$router.push({ name: 'customize' })
      }
    },
  },
}
</script>
