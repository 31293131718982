var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"week js-week"},[_c('LogHeader',{attrs:{"title":_vm.$t('components.week.weekly-log'),"date":_vm.selectedDate,"level":_vm.$scopeMap.week},on:{"addItemClicked":function($event){return _vm.addNewEntry(_vm.selectedDate, _vm.$scopeMap.week)},"lastBtnClicked":function($event){_vm.setDateAndScope({
        date: _vm.selectedDate.minus({ week: 1 }),
        scope: _vm.$scopeMap.week,
      })},"nextBtnClicked":function($event){_vm.setDateAndScope({
        date: _vm.selectedDate.plus({ week: 1 }),
        scope: _vm.$scopeMap.week,
      })}}}),_c('v-divider'),_c('EntryList',{staticClass:"mt-2 mb-6",attrs:{"entries":_vm.weekEntries,"updateData":{
      ..._vm.getDTfromDateObject(_vm.dateObject, true),
    },"context":"date"},on:{"addNewEntry":function($event){return _vm.addNewEntry(_vm.selectedDate, _vm.$scopeMap.week)}}},[_c('template',{slot:"addBtnText"},[_vm._v(_vm._s(_vm.$t('components.week.add-week-task')))])],2),_vm._l((_vm.daysOfTheWeek),function(day,i){return _c('WeekDay',{key:i,attrs:{"entries":_vm.weekEntriesDateMap[day.toISODate()] || [],"dayDt":day},on:{"addNewEntry":_vm.addNewEntry}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }