<template>
  <v-container>
    <v-alert
      v-model="welcomeAlert"
      dark
      color="grey darken-3 white--text"
      dismissible
    >
      <h2>
        {{ $t('views.dashboard.welcome-to-bullet') }}
      </h2>
      <i18n tag="div" path="views.dashboard.check-out-tutorials" class="mt-2">
        <v-btn @click="SET_HELP_DRAWER(true)" x-small light>{{
          $t('views.dashboard.tutorials')
        }}</v-btn>
      </i18n>
      <div class="text-caption">
        {{ $t('views.dashboard.tutorials-menu-tip') }}
      </div>
    </v-alert>

    <v-layout justify-space-around class="pt-3">
      <div class="dashboard-column">
        <div class="dashboard-item">
          <v-layout class="mb-5">
            <template v-for="option in sidebarOptions">
              <v-btn
                v-if="!(option.number === $scopeMap.day && !isMobile)"
                :key="option.number"
                class="mr-1"
                :color="sidebarContents === option.number ? 'primary' : ''"
                small
                @click="SET_SIDEBAR(option.number)"
              >
                {{ option.text }}
              </v-btn>
            </template>
            <v-btn
              small
              outlined
              v-if="!selectedDateIsToday"
              @click="setDateAndScope()"
              color="primary"
              >{{ $t('views.dashboard.today') }}</v-btn
            >
          </v-layout>
          <Overdue v-if="isMobile" class="dashboard-item mb-5" />
          <Year v-if="sidebarContents === $scopeMap.year" />
          <Month v-if="sidebarContents === $scopeMap.month" />
          <Week v-else-if="sidebarContents === $scopeMap.week" />
          <Day v-else-if="isMobile" class="dashboard-item" />
        </div>
      </div>

      <div v-if="!isMobile" class="dashboard-column mt-12">
        <Overdue class="dashboard-item mb-5" />
        <Day v-if="scope === $scopeMap.day" class="dashboard-item" />
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

import Year from '@/components/Year'
import Month from '@/components/Month'
import Day from '@/components/Day'
import Week from '@/components/Week'
import Overdue from '@/components/Overdue'

export default {
  components: {
    Year,
    Month,
    Day,
    Week,
    Overdue,
  },

  computed: {
    ...mapState('app', ['scope', 'sidebarContents']),
    ...mapGetters('app', ['isMobile', 'selectedDateIsToday']),

    welcomeAlert: {
      get() {
        return this.$store.state.app.welcomeAlert
      },
      set(newVal) {
        return this.SET_WELCOME_ALERT(newVal)
      },
    },

    sidebarOptions() {
      return [
        {
          text: this.$t('app.year'),
          number: 0,
        },
        {
          text: this.$t('app.month'),
          number: 1,
        },
        {
          text: this.$t('app.week'),
          number: 2,
        },
        {
          text: this.$t('app.day'),
          number: 3,
        },
      ]
    },
  },

  created() {
    if (this.$route.query.date)
      this.setDateAndScope({
        date: this.$DateTime.fromISO(this.$route.query.date),
      })
  },

  methods: {
    ...mapMutations('app', [
      'SET_SIDEBAR',
      'SET_HELP_DRAWER',
      'SET_WELCOME_ALERT',
    ]),
    ...mapActions('app', ['setDateAndScope']),
  },
}
</script>

<style lang="scss" scoped>
.dashboard-column {
  width: 100%;
}
.dashboard-item {
  margin: 0 auto;
  max-width: 360px;
}
</style>
