<template>
  <div class="month js-month">
    <LogHeader
      :title="$t('components.month.title')"
      :date="selectedDate"
      :level="$scopeMap.month"
      @addItemClicked="addNewEntry(selectedDate, $scopeMap.month)"
      @lastBtnClicked="
        setDateAndScope({
          date: selectedDate.minus({ month: 1 }),
          scope: $scopeMap.month,
        })
      "
      @nextBtnClicked="
        setDateAndScope({
          date: selectedDate.plus({ month: 1 }),
          scope: $scopeMap.month,
        })
      "
    />

    <v-divider></v-divider>

    <EntryList
      :entries="monthEntries"
      :updateData="{
        ...getDTfromDateObject(this.dateObject, true),
      }"
      context="date"
      class="mt-2 mb-6"
      @addNewEntry="addNewEntry(selectedDate, $scopeMap.month)"
    >
      <template slot="addBtnText">
        {{ $t('components.month.add-month-task') }}
      </template>
    </EntryList>

    <table class="responsive-table date-table">
      <tbody>
        <template v-for="date in daysOfMonth">
          <DayOfMonth
            :entries="monthDayEntriesByDateMap[date.toISODate()] || []"
            :key="date.toLocaleString()"
            :date="date"
            class="day clickable"
            :class="{
              grey:
                date.toLocaleString() === selectedDate.toLocaleString() &&
                scope === $scopeMap.day,
              'darken-3': $vuetify.theme.dark,
              'lighten-3': !$vuetify.theme.dark,
            }"
            @addNewEntry="addNewEntry(...$event)"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { getDateObject, getDTfromDateObject } from '@/helpers/convert-dates'

import LogHeader from '@/components/LogHeader'
import EntryList from '@/components/EntryList'
import DayOfMonth from '@/components/DayOfMonth'
import { EntriesDatabase } from '@/services/database'
import { DateTime } from 'luxon'

export default {
  components: {
    LogHeader,
    EntryList,
    DayOfMonth,
  },

  data() {
    return {
      monthEntries: [],
      monthEntryListener: null,
      monthDayEntries: [],
      monthDayEntryListener: null,
    }
  },

  computed: {
    ...mapState('app', ['selectedDate', 'scope']),

    daysOfMonth() {
      return Array.from({ length: this.selectedDate.daysInMonth }, (_, i) =>
        this.selectedDate.set({ day: i + 1 })
      )
    },

    dateObject() {
      return getDateObject(this.selectedDate, this.$scopeMap.month)
    },

    monthDayEntriesByDateMap() {
      const dateEntries = {}

      this.monthDayEntries.forEach(e => {
        const dateString = DateTime.fromJSDate(e.dateScheduled.toDate())
          .toUTC()
          .toISODate()

        return (dateEntries[dateString] = dateEntries[dateString] || []).push(e)
      })

      return dateEntries
    },
  },

  watch: {
    selectedDate: {
      handler: function(newVal, oldVal) {
        if (oldVal && newVal.toFormat('yyyy-LL') === oldVal.toFormat('yyyy-LL'))
          return

        this.getEntries(newVal)
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapActions('entries', ['newEntry']),
    ...mapActions('app', ['setDateAndScope']),
    ...mapMutations('entries', ['SET_SHOW_ENTRY']),

    getDTfromDateObject,

    disposeListener() {
      if (this.monthEntryListener) this.monthEntryListener()
      if (this.monthDayEntryListener) this.monthDayEntryListener()
    },

    getEntries() {
      this.disposeListener()

      this.monthEntryListener = EntriesDatabase.listenMonthEntries(
        this.selectedDate,
        data => {
          this.monthEntries = data
        }
      )
      this.monthDayEntryListener = EntriesDatabase.listenMonthDayEvents(
        this.selectedDate,
        data => {
          this.monthDayEntries = data
        }
      )
    },

    async addNewEntry(date, level, type) {
      const scheduled = getDateObject(date, level)
      const dateScheduled = getDTfromDateObject(scheduled, true)

      const newEntryDoc = await this.newEntry({
        ...dateScheduled,
        type,
      })

      this.$nextTick(() => {
        if (level === this.$scopeMap.month) {
          const el = document.querySelector(
            `.js-month .js-day-entry[data-id="${newEntryDoc.id}"] .js-title-input`
          )

          el.focus()
        } else {
          this.SET_SHOW_ENTRY(newEntryDoc.id)
        }
      })
    },
  },
}
</script>
