<template>
  <div class="week js-week">
    <LogHeader
      :title="$t('components.week.weekly-log')"
      :date="selectedDate"
      :level="$scopeMap.week"
      @addItemClicked="addNewEntry(selectedDate, $scopeMap.week)"
      @lastBtnClicked="
        setDateAndScope({
          date: selectedDate.minus({ week: 1 }),
          scope: $scopeMap.week,
        })
      "
      @nextBtnClicked="
        setDateAndScope({
          date: selectedDate.plus({ week: 1 }),
          scope: $scopeMap.week,
        })
      "
    />
    <v-divider></v-divider>

    <EntryList
      :entries="weekEntries"
      :updateData="{
        ...getDTfromDateObject(dateObject, true),
      }"
      context="date"
      class="mt-2 mb-6"
      @addNewEntry="addNewEntry(selectedDate, $scopeMap.week)"
    >
      <template slot="addBtnText">{{
        $t('components.week.add-week-task')
      }}</template>
    </EntryList>

    <WeekDay
      v-for="(day, i) in daysOfTheWeek"
      :entries="weekEntriesDateMap[day.toISODate()] || []"
      :key="i"
      :dayDt="day"
      @addNewEntry="addNewEntry"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getDateObject, getDTfromDateObject } from '@/helpers/convert-dates'
import { EntriesDatabase } from '@/services/database'

import LogHeader from '@/components/LogHeader'
import EntryList from '@/components/EntryList'
import WeekDay from '@/components/WeekDay'
import scopeMap from '@/helpers/scope-map'
import { DateTime } from 'luxon'

export default {
  components: {
    LogHeader,
    EntryList,
    WeekDay,
  },

  data() {
    return {
      entries: [],
      entriesListener: null,
    }
  },

  computed: {
    ...mapState('app', ['selectedDate', 'scope']),

    dateObject() {
      return getDateObject(this.selectedDate, this.$scopeMap.week)
    },

    daysOfTheWeek() {
      return Array.from({ length: 7 }, (_, i) =>
        this.selectedDate.set({ weekday: i + 1 })
      )
    },

    weekEntries() {
      return this.entries.filter(e => e.dateUnit === scopeMap.week)
    },

    weekEntriesDateMap() {
      const dateEntries = {}

      this.entries
        .filter(e => e.dateUnit === scopeMap.day)
        .forEach(e => {
          const dateString = DateTime.fromJSDate(e.dateScheduled.toDate())
            .toUTC()
            .toISODate()

          return (dateEntries[dateString] = dateEntries[dateString] || []).push(
            e
          )
        })

      return dateEntries
    },
  },

  watch: {
    selectedDate: {
      handler: function(newVal, oldVal) {
        if (oldVal && newVal.toFormat('yyyy-WW') === oldVal.toFormat('yyyy-WW'))
          return

        this.getEntries(newVal)
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapActions('entries', ['newEntry']),
    ...mapActions('app', ['setDateAndScope']),

    getDateObject,
    getDTfromDateObject,

    disposeListener() {
      if (this.entriesListener) this.entriesListener()
    },

    getEntries() {
      this.disposeListener()

      this.entriesListener = EntriesDatabase.listenWeekEntries(
        this.selectedDate,
        data => {
          this.entries = data
        }
      )
    },

    async addNewEntry(day, level) {
      const scheduled = getDateObject(day, level)
      const dateScheduled = getDTfromDateObject(scheduled, true)

      const newEntryDoc = await this.newEntry({
        ...dateScheduled,
        scope: this.$scopeMap.day,
      })

      this.$nextTick(() => {
        const el = document.querySelector(
          `.js-week .js-day-entry[data-id="${newEntryDoc.id}"] .js-title-input`
        )

        el.focus()
      })
    },
  },
}
</script>
