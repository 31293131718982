<template>
  <v-container class="container--slim">
    <v-layout class="mt-12 mb-8" justify-space-between align-center>
      <h1 class="text-h4 font-weight-bold">
        {{ $t('views.account.title') }}
      </h1>

      <v-dialog v-model="saveDialog" width="400">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" :disabled="!dataChanged" v-on="on">{{
            $t('app.save')
          }}</v-btn>
        </template>

        <v-card v-if="saveDialog">
          <v-card-title>
            {{ $t('views.account.enter-password') }}
          </v-card-title>

          <v-form ref="saveForm" @submit.prevent="save">
            <v-card-text>
              <v-text-field
                :label="$t('app.password')"
                v-model="existingPassword"
                type="password"
                outlined
                hide-details="auto"
                :rules="rules.password"
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" :loading="saving">
                {{ $t('app.save') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-layout>

    <h2 class="text-h5 mb-5">{{ $t('views.account.basic-information') }}</h2>
    <v-text-field
      :label="$t('app.email')"
      v-model="email"
      outlined
      :rules="rules.email"
    ></v-text-field>

    <v-dialog v-model="changePasswordDialog" width="400">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on">{{ $t('views.account.change-password') }}</v-btn>
      </template>

      <v-card v-if="changePasswordDialog">
        <v-card-title>
          {{ $t('views.account.change-password') }}
        </v-card-title>

        <v-form ref="changePasswordForm" @submit.prevent="changePassword">
          <v-card-text>
            <v-text-field
              :label="$t('views.account.old-password')"
              v-model="existingPassword"
              :rules="rules.password"
              type="password"
              class="my-4"
              hide-details="auto"
              outlined
            ></v-text-field>
            <v-divider class="my-4"></v-divider>
            <v-text-field
              :label="$t('views.account.new-password')"
              v-model="newPassword"
              :rules="rules.password"
              type="password"
              class="my-4"
              hide-details="auto"
              outlined
            ></v-text-field>
            <v-text-field
              :label="$t('views.account.confirm-password')"
              :rules="rules.confirmPassword"
              type="password"
              class="my-4"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" :loading="changingPassword">
              {{ $t('views.account.change-password') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <h2 class="text-h5 mb-5 mt-10">
      {{ $t('views.account.delete-account') }}
    </h2>
    <v-layout justify-space-between align-center>
      <div>{{ $t('views.account.delete-account-and-all-data') }}</div>

      <v-dialog v-model="deleteDialog" width="600">
        <template v-slot:activator="{ on }">
          <v-btn dark color="black" v-on="on">
            {{ $t('views.account.delete-account') }}
          </v-btn>
        </template>

        <v-card v-if="deleteDialog">
          <v-card-title>{{ $t('views.account.delete-account') }}</v-card-title>
          <v-form ref="deleteForm" @submit.prevent="deleteAcc">
            <v-card-text>
              <p>
                {{ $t('views.account.delete-account-warning') }}
              </p>

              <v-text-field
                :label="$t('app.password')"
                v-model="existingPassword"
                :rules="rules.password"
                type="password"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" type="submit" light :loading="deleting">
                <v-icon small class="mr-2">fa fa-skull-crossbones</v-icon>
                {{ $t('views.account.delete-account') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { functions } from '../firebase'

import { email, password, confirmPassword } from '../util/vuetify-rules'

export default {
  data() {
    return {
      email: '',
      existingPassword: '',
      newPassword: '',
      saveDialog: false,
      changePasswordDialog: false,
      deleteDialog: false,
      saving: false,
      changingPassword: false,
      deleting: false,
    }
  },

  computed: {
    ...mapState('auth', ['user', 'authObject']),

    dataChanged() {
      return this.user && this.email !== this.authObject.email
    },

    rules() {
      return {
        email,
        password,
        confirmPassword: confirmPassword(this.newPassword),
      }
    },
  },

  watch: {
    authObject: {
      handler: function(newVal) {
        if (newVal) this.email = newVal.email
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('auth', ['reauthenticate', 'deleteAccount']),

    async save() {
      if (this.$refs.saveForm.validate()) {
        this.saving = true

        try {
          await this.reauthenticate({
            email: this.authObject.email,
            password: this.existingPassword,
          })

          const updateEmailOnCall = functions.httpsCallable('updateEmailOnCall')

          await updateEmailOnCall({ email: this.email })

          await this.reauthenticate({
            email: this.email,
            password: this.existingPassword,
          })

          this.saveDialog = false
        } catch (error) {
          alert(error.message)
        } finally {
          this.existingPassword = ''
          this.saving = false
        }
      }
    },

    async changePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        this.changingPassword = true

        try {
          await this.reauthenticate({
            email: this.authObject.email,
            password: this.existingPassword,
          })

          await this.authObject.updatePassword(this.newPassword)

          this.changePasswordDialog = false
        } catch (error) {
          alert(error.message)
        } finally {
          this.changingPassword = false
          this.$refs.changePasswordForm.reset()
        }
      }
    },

    async deleteAcc() {
      if (this.$refs.deleteForm.validate()) {
        this.deleting = true

        try {
          await this.deleteAccount({
            email: this.authObject.email,
            password: this.existingPassword,
          })
        } catch (error) {
          alert(error.message)
        } finally {
          this.$refs.deleteForm.reset()
          this.deleting = false
        }
      }
    },
  },
}
</script>
