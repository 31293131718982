var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"year js-year"},[_c('LogHeader',{attrs:{"title":_vm.$t('components.year.title'),"date":_vm.selectedDate,"level":_vm.$scopeMap.year},on:{"addItemClicked":function($event){return _vm.addNewEntry(_vm.selectedDate, _vm.$scopeMap.year)},"lastBtnClicked":function($event){_vm.setDateAndScope({
        date: _vm.selectedDate.minus({ year: 1 }),
        scope: _vm.$scopeMap.year,
      })},"nextBtnClicked":function($event){_vm.setDateAndScope({
        date: _vm.selectedDate.plus({ year: 1 }),
        scope: _vm.$scopeMap.year,
      })}}}),_c('v-divider'),_c('EntryList',{staticClass:"mt-2 mb-6",attrs:{"entries":_vm.yearEntries,"updateData":{
      ..._vm.getDTfromDateObject(this.dateObject, true),
    },"context":"date"},on:{"addNewEntry":function($event){return _vm.addNewEntry(_vm.selectedDate, _vm.$scopeMap.year)}}},[_c('template',{slot:"addBtnText"},[_vm._v(" "+_vm._s(_vm.$t('components.year.add-year-task'))+" ")])],2),_vm._l((_vm.monthsOfYear),function(monthDT){return _c('MonthOfYear',{key:monthDT.toLocaleString(),attrs:{"monthEvents":_vm.yearMonthEntriesByDateMap[monthDT.toFormat('yyyy-LL')] || [],"monthDT":monthDT},on:{"addNewEntry":function($event){return _vm.addNewEntry(...$event)}}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }