<template>
  <v-container class="container--slim">
    <h1 class="text-h4 mt-12 mb-8 font-weight-bold">
      {{ $t('views.customize.customize') }}
    </h1>

    <CustomizePrompts class="mb-4" />

    <CustomizeColors />
  </v-container>
</template>

<script>
import CustomizePrompts from '@/components/CustomizePrompts'
import CustomizeColors from '@/components/CustomizeColors'

export default {
  components: {
    CustomizePrompts,
    CustomizeColors,
  },
}
</script>
