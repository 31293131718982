<template>
  <v-container class="container--slim pt-12" v-if="tag">
    <div class="d-flex align-center justify-space-between">
      <h1>
        {{ $route.params.tagId }}
      </h1>

      <div>
        <v-dialog width="256">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon small :color="minimal ? '' : tag.color"
                >fas fa-palette</v-icon
              >
            </v-btn>
          </template>

          <v-card>
            <v-btn
              v-for="color in getColors('lighten3')"
              :key="color"
              :color="color"
              class="elevation-0"
              tile
              @click="updateColor(color)"
            ></v-btn>
          </v-card>
        </v-dialog>
        <v-btn icon @click="deleteTag">
          <v-icon small>fas fa-trash</v-icon>
        </v-btn>
      </div>
    </div>

    <EntryList
      context="tag"
      :entries="entries"
      :ordered="false"
      :addBtn="false"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getColors } from '@/helpers/random-color'

import EntryList from '@/components/EntryList.vue'
import { EntriesDatabase } from '@/services/database'

export default {
  components: {
    EntryList,
  },

  data() {
    return {
      entries: [],
      entryObservable$: null,
    }
  },

  computed: {
    ...mapState('tags', ['tags']),
    ...mapState('app', ['minimal']),

    tag() {
      return this.tags.find(t => t.id === this.$route.params.tagId)
    },
  },

  watch: {
    tag: {
      handler: function(newVal) {
        if (newVal) {
          this.getEntries(newVal.id)
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapActions('tags', ['destroyTag', 'updateTag']),

    disposeListener() {
      if (this.entryObservable$) this.entryObservable$.unsubscribe()
    },

    getEntries(tagId) {
      this.disposeListener()

      this.entryObservable$ = EntriesDatabase.observeTagEntries$(
        tagId,
        data => {
          this.entries = data
        }
      )
    },

    deleteTag() {
      if (confirm(this.$t('views.tag.delete-warning'))) {
        this.destroyTag(this.$route.params.tagId)
        this.$router.push({ name: 'dashboard' })
      }
    },

    updateColor(color) {
      this.updateTag({
        tagId: this.$route.params.tagId,
        data: {
          color,
        },
      })
    },

    getColors,
  },
}
</script>
