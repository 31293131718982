<template>
  <v-container class="container--slim inbox">
    <v-layout class="mt-12 mb-8" justify-space-between>
      <div>
        <h1 class="text-h4 mr-5">{{ $t('views.inbox.title') }}</h1>
        <div class="text-caption grey--text">
          {{ $t('views.inbox.inbox-tip') }}
        </div>
      </div>
      <v-btn icon @click="addNewEntry">
        <v-icon>fas fa-plus</v-icon>
      </v-btn>
    </v-layout>

    <EntryList :entries="entries" context="list" @addNewEntry="addNewEntry" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EntryList from '@/components/EntryList.vue'
import { EntriesDatabase } from '@/services/database'

export default {
  components: {
    EntryList,
  },

  data() {
    return {
      title: '',
      entries: [],
      entryObservable$: null,
    }
  },

  created() {
    this.getEntries()
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapActions('entries', ['newEntry']),

    disposeListener() {
      if (this.entryObservable$) this.entryObservable$.unsubscribe()
    },

    getEntries() {
      this.disposeListener()

      this.entryObservable$ = EntriesDatabase.observeInboxEntries$(data => {
        this.entries = data
      })
    },

    async addNewEntry() {
      const newEntryDoc = await this.newEntry()

      this.$nextTick(() => {
        const el = document.querySelector(
          `.inbox .js-day-entry[data-id="${newEntryDoc.id}"] .js-title-input`
        )

        el.focus()
      })
    },
  },
}
</script>
