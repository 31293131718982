<template>
  <div class="day" v-if="entries.length">
    <div class="text-h5">{{ $t('components.overdue.title') }}</div>

    <v-divider class="mb-2"></v-divider>

    <EntryList
      :entries="entries"
      :group="{ name: 'entries', pull: true, put: false }"
      context="date"
      :ordered="false"
    />
  </div>
</template>

<script>
import EntryList from '@/components/EntryList'
import { EntriesDatabase } from '@/services/database'

export default {
  components: {
    EntryList,
  },

  data() {
    return {
      entries: [],
      entries$: null,
    }
  },

  created() {
    this.getEntries()
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    disposeListener() {
      if (this.entries$) this.entries$.unsubscribe()
    },

    getEntries() {
      this.disposeListener()

      this.entries$ = EntriesDatabase.observeOverdue$(data => {
        this.entries = data
      })
    },
  },
}
</script>
