<template>
  <div class="year js-year">
    <LogHeader
      :title="$t('components.year.title')"
      :date="selectedDate"
      :level="$scopeMap.year"
      @addItemClicked="addNewEntry(selectedDate, $scopeMap.year)"
      @lastBtnClicked="
        setDateAndScope({
          date: selectedDate.minus({ year: 1 }),
          scope: $scopeMap.year,
        })
      "
      @nextBtnClicked="
        setDateAndScope({
          date: selectedDate.plus({ year: 1 }),
          scope: $scopeMap.year,
        })
      "
    />
    <v-divider />

    <EntryList
      :entries="yearEntries"
      :updateData="{
        ...getDTfromDateObject(this.dateObject, true),
      }"
      context="date"
      class="mt-2 mb-6"
      @addNewEntry="addNewEntry(selectedDate, $scopeMap.year)"
    >
      <template slot="addBtnText">
        {{ $t('components.year.add-year-task') }}
      </template>
    </EntryList>

    <MonthOfYear
      v-for="monthDT in monthsOfYear"
      :monthEvents="
        yearMonthEntriesByDateMap[monthDT.toFormat('yyyy-LL')] || []
      "
      :monthDT="monthDT"
      :key="monthDT.toLocaleString()"
      @addNewEntry="addNewEntry(...$event)"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { getDateObject, getDTfromDateObject } from '../helpers/convert-dates'

import LogHeader from '@/components/LogHeader'
import EntryList from '@/components/EntryList'
import MonthOfYear from '@/components/MonthOfYear'
import { EntriesDatabase } from '@/services/database'
import { DateTime } from 'luxon'

export default {
  components: {
    LogHeader,
    EntryList,
    MonthOfYear,
  },

  data() {
    return {
      yearEntries: [],
      yearEntriesListener: null,
      yearDayEvents: [],
      yearDayEventsListener: null,
    }
  },

  computed: {
    ...mapState('app', ['selectedDate', 'scope']),

    dateObject() {
      return getDateObject(this.selectedDate, this.$scopeMap.year)
    },

    monthsOfYear() {
      return Array.from({ length: 12 }, (_, i) =>
        this.selectedDate.set({ month: i + 1 })
      )
    },

    yearMonthEntriesByDateMap() {
      const dateEntries = {}

      this.yearDayEvents.forEach(e => {
        const dateString = DateTime.fromJSDate(e.dateScheduled.toDate())
          .toUTC()
          .toFormat('yyyy-LL')

        return (dateEntries[dateString] = dateEntries[dateString] || []).push(e)
      })

      return dateEntries
    },
  },

  watch: {
    selectedDate: {
      handler: function(newVal, oldVal) {
        if (oldVal && newVal.year === oldVal.year) return

        this.getEntries(newVal)
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapActions('entries', ['newEntry']),
    ...mapMutations('entries', ['SET_SHOW_ENTRY']),
    ...mapActions('app', ['setDateAndScope']),

    getDTfromDateObject,

    disposeListener() {
      if (this.yearEntriesListener) this.yearEntriesListener()
      if (this.yearDayEventsListener) this.yearDayEventsListener()
    },

    getEntries() {
      this.disposeListener()

      this.entriesListener = EntriesDatabase.listenYearEntries(
        this.selectedDate,
        data => {
          this.yearEntries = data
        }
      )
      this.yearDayEventsListener = EntriesDatabase.listenYearDayEvents(
        this.selectedDate,
        data => {
          this.yearDayEvents = data
        }
      )
    },

    async addNewEntry(date, level, type) {
      const scheduled = getDateObject(date, level)
      const dateScheduled = getDTfromDateObject(scheduled, true)

      const newEntryDoc = await this.newEntry({
        ...dateScheduled,
        type,
      })

      this.$nextTick(() => {
        if (level === this.$scopeMap.year) {
          const el = document.querySelector(
            `.js-year .js-day-entry[data-id="${newEntryDoc.id}"] .js-title-input`
          )

          el.focus()
        } else {
          this.SET_SHOW_ENTRY(newEntryDoc.id)
        }
      })
    },
  },
}
</script>
