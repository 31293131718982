<template>
  <div>
    <v-layout class="text-h5" justify-space-between>
      <div>{{ title }}</div>
      <v-btn icon @click="$emit('addItemClicked')">
        <v-icon>fas fa-plus</v-icon>
      </v-btn>
    </v-layout>
    <v-layout class="text-caption" justify-space-between>
      <div>
        {{ dateString }}
      </div>
      <div>
        <v-menu
          v-model="showPicker"
          transition="scale-transition"
          offset-y
          eager
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon x-small>
              <v-icon x-small>
                fa fa-calendar
              </v-icon>
            </v-btn>
          </template>
          <v-date-picker
            ref="picker"
            :value="date.toISODate()"
            :allowed-dates="allowedDates"
            :type="level === $scopeMap.month ? 'month' : 'date'"
            first-day-of-week="1"
            @change="
              setDateAndScope({ date: $DateTime.fromISO($event), scope: level })
            "
            @click:year="
              setDateAndScope({ date: $DateTime.fromISO($event), scope: level })
            "
          >
          </v-date-picker>
        </v-menu>
        <v-btn icon x-small @click="$emit('lastBtnClicked')">
          <v-icon small>fas fa-caret-left</v-icon>
        </v-btn>
        <v-btn icon x-small @click="$emit('nextBtnClicked')">
          <v-icon small>fas fa-caret-right</v-icon>
        </v-btn>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { dtToString } from '@/helpers/convert-dates'

export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    date: {
      required: true,
      type: Object,
    },
    level: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      showPicker: false,
    }
  },

  computed: {
    dateString() {
      return dtToString(this.date, this.level)
    },
  },

  watch: {
    showPicker: function(newVal) {
      if (newVal && this.level === this.$scopeMap.year) {
        setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR'
        }, 3)
      }
    },
  },

  methods: {
    ...mapActions('app', ['setDateAndScope']),

    allowedDates(val) {
      return this.level === this.$scopeMap.week
        ? this.$DateTime.fromISO(val).weekday === 1
        : true
    },
  },
}
</script>
