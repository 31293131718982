<template>
  <v-container>
    <div v-if="loading" class="d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card outlined v-else-if="error" class="pa-4">{{ error }}</v-card>
  </v-container>
</template>

<script>
import Microservices from '@/services/functions'

export default {
  name: 'SSO',

  data() {
    return {
      loading: false,
      error: null,
    }
  },

  created() {
    this.authorize()
  },

  methods: {
    async authorize() {
      this.loading = true
      this.error = null

      const { client_id, redirect_uri, scope } = this.$route.query

      try {
        const response = await Microservices.sso()

        const ssoToken = response.data

        var redirectURL = this.getRedirectURL(ssoToken)

        if (redirectURL) {
          window.location.assign(redirectURL)
        }
      } catch (error) {
        this.error = error

        this.loading = false
      }
    },

    getQueryParameterByName(name) {
      var pairStrings = window.location.search.slice(1).split('&')

      var pairs = pairStrings.map(function(pair) {
        return pair.split('=')
      })

      return pairs.reduce(function(value, pair) {
        if (value) return value
        return pair[0] === name ? decodeURIComponent(pair[1]) : null
      }, null)
    },

    getRedirectURL(ssoToken) {
      var redirectURL = this.getQueryParameterByName('redirect')

      var companyID = this.getQueryParameterByName('companyID')

      if (redirectURL.indexOf('https://') !== 0 || !companyID) {
        return null
      }

      return (
        'https://canny.io/api/redirects/sso?companyID=' +
        companyID +
        '&ssoToken=' +
        ssoToken +
        '&redirect=' +
        redirectURL
      )
    },
  },
}
</script>
