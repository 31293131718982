<template>
  <v-container>
    <h1>Admin</h1>

    <div>
      {{ user }}
    </div>

    <div>{{ claims }}</div>

    <v-text-field
      label="Add emails"
      v-model="emailString"
      type="email"
    ></v-text-field>
    <div>
      <v-btn @click="addBeta(true)">Add beta users</v-btn>
      <v-btn @click="addAdmin(false)">Remove beta users</v-btn>
      <v-btn @click="removeAllBeta">Remove ALL beta</v-btn>
    </div>
    <div class="mt-2">
      <v-btn @click="addFeedbackUsers">Add feedback users</v-btn>
    </div>
    <div class="mt-2">
      <v-btn @click="addAdmin(true)">Add admin users</v-btn>
      <v-btn @click="addAdmin(false)">Remove admin users</v-btn>
    </div>
    <div class="mt-2">
      <v-btn @click="addPremium">Add Premium to ALL</v-btn>
    </div>
  </v-container>
</template>

<script>
import { functions } from '@/firebase'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      emailString: '',
    }
  },

  computed: {
    ...mapState('auth', ['user', 'claims']),
  },

  methods: {
    async addBeta(value = true) {
      try {
        const addBetaUserOnCall = functions.httpsCallable('addBetaUserOnCall')

        await addBetaUserOnCall({
          emailString: this.emailString,
          value,
        })

        alert('Done')

        this.emailString = ''
      } catch (error) {
        alert(error.message)
      }
    },

    async removeAllBeta() {
      if (confirm('Are you sure you want to remove all beta users?')) {
        try {
          const removeAllBetaUsers = functions.httpsCallable(
            'removeAllBetaUsers'
          )

          await removeAllBetaUsers()

          alert('Done')
        } catch (error) {
          alert(error.message)
        }
      }
    },

    async addAdmin(value = true) {
      if (confirm('Are you sure you want to add this admin')) {
        try {
          const addAdminOnCall = functions.httpsCallable('addAdminOnCall')

          await addAdminOnCall({
            emailString: this.emailString,
            value,
          })

          alert('Done')

          this.emailString = ''
        } catch (error) {
          alert(error.message)
        }
      }
    },

    async addFeedbackUsers() {
      try {
        const addFeedbackUserOnCall = functions.httpsCallable(
          'addFeedbackUserOnCall'
        )

        await addFeedbackUserOnCall({
          emailString: this.emailString,
        })

        alert('Done')

        this.emailString = ''
      } catch (error) {
        alert(error.message)
      }
    },

    async addPremium() {
      try {
        const addPremiumOnCall = functions.httpsCallable('addPremiumOnCall')

        await addPremiumOnCall()

        alert('Done')
      } catch (error) {
        alert(error.message)
      }
    },
  },
}
</script>
