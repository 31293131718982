<template>
  <v-container v-if="list" class="container--slim list js-list">
    <div class="d-flex align-top mt-12">
      <v-textarea
        ref="titleInput"
        v-model="title"
        auto-grow
        rows="1"
        solo
        flat
        background-color="transparent"
        class="list__title text-h4 pa-0"
        dense
        placeholder="Title"
        @blur="updateTitle"
        @keypress.enter.exact="$event.target.blur()"
      ></v-textarea>

      <div class="d-flex">
        <v-btn icon @click="addNewEntry">
          <v-icon>fas fa-plus</v-icon>
        </v-btn>

        <v-dialog width="256">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon small :color="minimal ? '' : list.color"
                >fas fa-palette</v-icon
              >
            </v-btn>
          </template>

          <v-card>
            <v-btn
              v-for="color in getColors('accent2')"
              :key="color"
              :color="color"
              class="elevation-0"
              tile
              @click="updateColor(color)"
            ></v-btn>
          </v-card>
        </v-dialog>

        <v-btn icon @click="deleteList">
          <v-icon small>fas fa-trash</v-icon>
        </v-btn>
      </div>
    </div>

    <EntryList :entries="entries" context="list" @addNewEntry="addNewEntry" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { firebase } from '@/firebase'
import { getColors } from '@/helpers/random-color'

import EntryList from '@/components/EntryList.vue'
import { EntriesDatabase } from '@/services/database'

export default {
  components: {
    EntryList,
  },

  data() {
    return {
      title: '',
      entries: [],
      entryObservable$: null,
    }
  },

  computed: {
    ...mapGetters('lists', ['listMap']),
    ...mapState('app', ['minimal']),

    listId() {
      return this.$route.params.listId
    },

    list() {
      return this.listMap[this.listId]
    },

    listTitle() {
      return this.list && this.list.title
    },
  },

  watch: {
    list: {
      handler: function(newVal) {
        if (newVal) {
          const title = newVal.title
          this.title = title
          this.$nextTick(() => {
            if (!title && this.$refs.titleInput) this.$refs.titleInput.focus()
          })
          this.getEntries(newVal.id)
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapActions('lists', ['updateList', 'destroyList']),
    ...mapActions('entries', ['newEntry']),
    getColors,

    disposeListener() {
      if (this.entryObservable$) this.entryObservable$.unsubscribe()
    },

    getEntries(listId) {
      this.disposeListener()

      this.entryObservable$ = EntriesDatabase.observeListEntries$(
        listId,
        data => {
          this.entries = data
        }
      )
    },

    async addNewEntry() {
      const newEntryDoc = await this.newEntry({
        list: this.listId,
      })

      this.$nextTick(() => {
        const el = document.querySelector(
          `.js-list .js-day-entry[data-id="${newEntryDoc.id}"] .js-title-input`
        )

        el.focus()
      })
    },

    updateTitle() {
      this.updateList({
        id: this.listId,
        data: {
          title: this.title,
        },
      })
    },

    updateColor(color) {
      this.updateList({
        id: this.listId,
        data: {
          color,
        },
      })
    },

    deleteList() {
      if (confirm(this.$t('views.list.delete-list'))) {
        this.destroyList(this.listId)
        this.$router.push({ name: 'dashboard' })
      }
    },

    nowTimestamp() {
      return firebase.firestore.Timestamp.now()
    },
  },
}
</script>

<style lang="scss">
.list {
  &__title {
    &.v-input input {
      color: inherit;
    }
  }
}
</style>
