var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"month js-month"},[_c('LogHeader',{attrs:{"title":_vm.$t('components.month.title'),"date":_vm.selectedDate,"level":_vm.$scopeMap.month},on:{"addItemClicked":function($event){return _vm.addNewEntry(_vm.selectedDate, _vm.$scopeMap.month)},"lastBtnClicked":function($event){_vm.setDateAndScope({
        date: _vm.selectedDate.minus({ month: 1 }),
        scope: _vm.$scopeMap.month,
      })},"nextBtnClicked":function($event){_vm.setDateAndScope({
        date: _vm.selectedDate.plus({ month: 1 }),
        scope: _vm.$scopeMap.month,
      })}}}),_c('v-divider'),_c('EntryList',{staticClass:"mt-2 mb-6",attrs:{"entries":_vm.monthEntries,"updateData":{
      ..._vm.getDTfromDateObject(this.dateObject, true),
    },"context":"date"},on:{"addNewEntry":function($event){return _vm.addNewEntry(_vm.selectedDate, _vm.$scopeMap.month)}}},[_c('template',{slot:"addBtnText"},[_vm._v(" "+_vm._s(_vm.$t('components.month.add-month-task'))+" ")])],2),_c('table',{staticClass:"responsive-table date-table"},[_c('tbody',[_vm._l((_vm.daysOfMonth),function(date){return [_c('DayOfMonth',{key:date.toLocaleString(),staticClass:"day clickable",class:{
            grey:
              date.toLocaleString() === _vm.selectedDate.toLocaleString() &&
              _vm.scope === _vm.$scopeMap.day,
            'darken-3': _vm.$vuetify.theme.dark,
            'lighten-3': !_vm.$vuetify.theme.dark,
          },attrs:{"entries":_vm.monthDayEntriesByDateMap[date.toISODate()] || [],"date":date},on:{"addNewEntry":function($event){return _vm.addNewEntry(...$event)}}})]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }