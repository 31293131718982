<template>
  <div>
    <div class="d-flex align-center">
      <h2>{{ $t('components.customize-prompts.title') }}</h2>

      <v-dialog v-model="addPromptDialog" width="500">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="ml-4">
            <v-icon>fa fa-plus</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            {{ $t('components.customize-prompts.add-prompt') }}
          </v-card-title>

          <PromptEditor @promptAdded="addPromptDialog = false" />
        </v-card>
      </v-dialog>

      <v-spacer></v-spacer>

      <v-btn text small @click="showArchived = !showArchived">
        <template v-if="showArchived">
          {{ $t('components.customize-prompts.hide-archived') }}
        </template>
        <template v-else>
          {{ $t('components.customize-prompts.show-archived') }}
        </template>
      </v-btn>
    </div>

    <div class="text-caption grey--text mb-2">
      {{ $t('components.customize-prompts.prompts-appear') }}
    </div>

    <v-row>
      <v-col v-for="prompt in orderedPrompts" :key="prompt.id" sm="4">
        <v-card
          :to="{ name: 'prompt', params: { promptId: prompt.id } }"
          :class="{ translucent: prompt.archived }"
        >
          <v-card-text>
            {{ prompt.text }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import PromptEditor from '@/components/PromptEditor'

export default {
  components: {
    PromptEditor,
  },

  data() {
    return {
      addPromptDialog: false,
      showArchived: false,
    }
  },

  computed: {
    ...mapState('prompts', ['prompts']),

    orderedPrompts() {
      let prompts = this.prompts.filter(p => !p.deleting)
      if (!this.showArchived) return prompts.filter(p => !p.archived)
      return prompts.sort((a, b) => a.archived - b.archived)
    },
  },
}
</script>
