<template>
  <tr
    class="month-day"
    @click="setDateAndScope({ date, scope: $scopeMap.day })"
  >
    <td class="pl-1">
      {{ date.day }}
    </td>
    <td>
      {{ dayOfWeek }}
    </td>
    <td class="month-day__add">
      <v-btn
        x-small
        icon
        @click.stop="$emit('addNewEntry', [date, $scopeMap.day, 'event'])"
      >
        <v-icon x-small>fas fa-plus</v-icon>
      </v-btn>
    </td>
    <td class="pl-3">
      <v-layout>
        <v-icon
          v-if="date.toLocaleString() === $DateTime.local().toLocaleString()"
          small
          color="primary"
          class="mr-1"
        >
          far fa-calendar-check
        </v-icon>

        <v-flex>
          <draggable
            v-model="orderedEntries"
            @add="reorderSave"
            @end="reorderSave"
            class="month-day__entries"
          >
            <MonthEntry
              v-for="entry in orderedEntries"
              :key="entry.id"
              :entry="entry"
              class="mr-1"
            />
          </draggable>
        </v-flex>
      </v-layout>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import { getDateObject, getDTfromDateObject } from '@/helpers/convert-dates'
import { orderEntries } from '@/helpers/order-entries'

import MonthEntry from '@/components/MonthEntry'
import draggable from 'vuedraggable'

export default {
  components: {
    MonthEntry,
    draggable,
  },

  props: {
    date: {
      required: true,
    },
    entries: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      context: 'date',
      orderedEntries: [],
      updateData: {
        ...getDTfromDateObject(getDateObject(this.date), true),
      },
    }
  },

  computed: {
    dayOfWeek() {
      const weekday = this.date.weekday
      if (weekday === 1) {
        return this.$t('components.day-month.mon-short')
      } else if (weekday === 2) {
        return this.$t('components.day-month.tue-short')
      } else if (weekday === 3) {
        return this.$t('components.day-month.wed-short')
      } else if (weekday === 4) {
        return this.$t('components.day-month.thu-short')
      } else if (weekday === 5) {
        return this.$t('components.day-month.fri-short')
      } else if (weekday === 6) {
        return this.$t('components.day-month.sat-short')
      } else {
        return this.$t('components.day-month.sun-short')
      }
    },
  },

  watch: {
    entries: {
      handler: function(newVal) {
        this.orderedEntries = orderEntries(newVal.slice(0), this.context, true)
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('entries', [
      'newEntry',
      'reorderEntriesSave',
      'reorderEntriesState',
    ]),
    ...mapActions('app', ['setDateAndScope']),

    reorderSave() {
      this.reorderEntriesSave({
        entries: this.orderedEntries,
        context: this.context,
        updateData: this.updateData,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.month-day {
  td:first-child {
    width: 2.5em;
  }

  td:nth-child(2),
  td:nth-child(3) {
    // font-family: monospace;
    font-weight: 300;
    text-align: center;
    width: 1rem;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    & .month-day__add {
      opacity: 1;
    }
  }

  &__add {
    opacity: 0;
  }
  &__entries {
    list-style-type: none;
  }
}
</style>
