var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"day js-day"},[_c('LogHeader',{attrs:{"title":_vm.$t('components.day.daily-log'),"date":_vm.selectedDate,"level":_vm.$scopeMap.day},on:{"addItemClicked":_vm.addNewEntry,"lastBtnClicked":function($event){_vm.setDateAndScope({
        date: _vm.selectedDate.minus({ day: 1 }),
        scope: _vm.$scopeMap.day,
      })},"nextBtnClicked":function($event){_vm.setDateAndScope({
        date: _vm.selectedDate.plus({ day: 1 }),
        scope: _vm.$scopeMap.day,
      })}}}),_c('v-divider',{staticClass:"mb-2"}),_c('EntryList',{staticClass:"mb-10",attrs:{"entries":_vm.displayEntries,"updateData":{

      ..._vm.getDTfromDateObject(this.dateObject, true),
    },"context":"date","loading":_vm.loadingEntries},on:{"addNewEntry":_vm.addNewEntry}}),_c('div',{staticClass:"mb-10"},_vm._l((_vm.promptsEntries),function(promptEntry){return _c('v-textarea',{key:promptEntry.id,staticClass:"borderless-input",attrs:{"label":promptEntry.label,"value":promptEntry.value,"rows":"1","hide-details":"","autocomplete":"off","auto-grow":""},on:{"blur":function($event){return _vm.addPromptNote($event, promptEntry.promptId, promptEntry.entryId)}}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }