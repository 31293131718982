<template>
  <div>
    <a class="caption" @click="selectMonth">
      {{
        monthDT.toLocaleString({
          month: 'long',
        })
      }}
    </a>

    <table class="responsive-table month-table">
      <tbody>
        <template v-for="monthDay in monthDayEntriesByDateMap">
          <DayOfMonth
            :date="monthDay.date"
            :entries="monthDay.entries || []"
            :key="monthDay.date.toISODate()"
            class="day clickable"
            :class="{
              grey:
                monthDay.date.toISODate() === monthDT.toISODate() &&
                scope === $scopeMap.day,
              'darken-3': $vuetify.theme.dark,
              'lighten-3': !$vuetify.theme.dark,
            }"
            @addNewEntry="$emit('addNewEntry', $event)"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import DayOfMonth from '@/components/DayOfMonth'
import { DateTime } from 'luxon'

export default {
  components: {
    DayOfMonth,
  },

  props: {
    monthDT: {
      required: true,
      type: Object,
    },
    monthEvents: {
      required: true,
      type: Array,
    },
  },

  computed: {
    monthDayEntriesByDateMap() {
      const dateEntries = {}

      this.monthEvents.forEach(e => {
        const dateString = DateTime.fromJSDate(e.dateScheduled.toDate())
          .toUTC()
          .toISODate()

        return (dateEntries[dateString] = dateEntries[dateString] || []).push(e)
      })

      return Object.entries(dateEntries).map(([key, val]) => {
        return {
          date: DateTime.fromISO(key),
          entries: val,
        }
      })
    },
  },

  methods: {
    ...mapActions('app', ['setDateAndScope']),
    ...mapMutations('app', ['SET_SIDEBAR']),

    selectMonth() {
      this.setDateAndScope({
        date: this.monthDT,
        scope: this.$scopeMap.month,
      })
      this.SET_SIDEBAR(this.$scopeMap.month)
    },
  },
}
</script>
