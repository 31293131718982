<template>
  <div>
    <v-card-text>
      <v-text-field
        v-model="text"
        :label="
          $t('components.prompt-editor.e-g-what-do-you-feel-grateful-for')
        "
        solo
        autofocus
        hide-details
      ></v-text-field>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="addPrompt">{{ $t('app.add') }}</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      text: '',
    }
  },

  methods: {
    ...mapActions('prompts', ['createPrompt']),

    async addPrompt() {
      try {
        await this.createPrompt(this.text)

        this.text = ''
        this.$emit('promptAdded')
      } catch (error) {
        alert(error.message)
      }
    },
  },
}
</script>
