var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"caption",on:{"click":_vm.selectMonth}},[_vm._v(" "+_vm._s(_vm.monthDT.toLocaleString({ month: 'long', }))+" ")]),_c('table',{staticClass:"responsive-table month-table"},[_c('tbody',[_vm._l((_vm.monthDayEntriesByDateMap),function(monthDay){return [_c('DayOfMonth',{key:monthDay.date.toISODate(),staticClass:"day clickable",class:{
            grey:
              monthDay.date.toISODate() === _vm.monthDT.toISODate() &&
              _vm.scope === _vm.$scopeMap.day,
            'darken-3': _vm.$vuetify.theme.dark,
            'lighten-3': !_vm.$vuetify.theme.dark,
          },attrs:{"date":monthDay.date,"entries":monthDay.entries || []},on:{"addNewEntry":function($event){return _vm.$emit('addNewEntry', $event)}}})]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }