<template>
  <v-card
    class="mt-2 weekday pa-2 clickable"
    :class="{
      'elevation-3':
        dayDt.toLocaleString() === selectedDate.toLocaleString() &&
        scope === $scopeMap.day,
    }"
    :color="
      ($vuetify.theme.dark &&
        dayDt.toLocaleString() === selectedDate.toLocaleString() &&
        scope === $scopeMap.day &&
        'grey darken-3') ||
        ''
    "
    :outlined="dayDt.toLocaleString() === $DateTime.local().toLocaleString()"
    flat
    @click.native.self="setDateAndScope({ date: dayDt })"
  >
    <div
      class="weekday__day font-weight-medium"
      @click="setDateAndScope({ date: dayDt })"
    >
      <span>
        <v-icon
          v-if="dayDt.toLocaleString() === $DateTime.local().toLocaleString()"
          small
          class="mr-2"
          color="primary"
        >
          far fa-calendar-check
        </v-icon>
        <span v-if="dayDt.weekday === 1">
          {{
            dayDt.toLocaleString({
              weekday: 'long',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })
          }}
        </span>
        <span v-else>
          {{ dayDt.toLocaleString({ weekday: 'long', day: 'numeric' }) }}
        </span>
      </span>
      <v-btn
        icon
        x-small
        @click="$emit('addNewEntry', dayDt)"
        class="weekday__plus"
      >
        <v-icon x-small>fas fa-plus</v-icon>
      </v-btn>
    </div>

    <div
      class="text-caption grey--text d-inline-block"
      v-if="tasks.length && tasks.every(e => e.dateComplete)"
    >
      {{ $t('components.week.tasks-complete') }} 🤩
    </div>

    <EntryList
      :entries="entries.filter(e => !e.dateComplete)"
      :updateData="{
        ...getDTfromDateObject(getDateObject(dayDt), true),
      }"
      :add-btn="false"
      context="date"
      @addNewEntry="$emit('addNewEntry', dayDt)"
    />
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getDateObject, getDTfromDateObject } from '@/helpers/convert-dates'

import EntryList from '@/components/EntryList'

export default {
  components: {
    EntryList,
  },

  props: {
    dayDt: {
      required: true,
      type: Object,
    },
    entries: {
      required: true,
      type: Array,
    },
  },

  computed: {
    ...mapState('app', ['selectedDate', 'scope']),

    tasks() {
      return this.entries.filter(e => e.type === 'task')
    },
  },

  methods: {
    ...mapActions('app', ['setDateAndScope']),

    getDateObject,
    getDTfromDateObject,
  },
}
</script>

<style lang="scss" scoped>
.weekday {
  border: 1px solid transparent;
  &:hover {
    .weekday__plus {
      display: inline;
    }
  }
  &__plus {
    display: none;
  }
  &--selected {
    border: 1px solid rgb(200, 200, 200);
  }
}
</style>
