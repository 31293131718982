<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <ul class="no-styles">
          <li v-for="(perk, i) in perks" :key="i">
            <v-icon x-small class="mr-2">{{ perk.icon }}</v-icon>
            {{ perk.text }}
          </li>
        </ul>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="6"
        class="text-center d-flex flex-column justify-space-between"
      >
        <p class="text-h5">
          {{
            $t('components.premium-form.from-price', {
              price: $n(3, 'currencyNoDecimal'),
            })
          }}
        </p>
      </v-col>
    </v-row>

    <v-btn
      block
      color="primary"
      class="mt-2"
      :loading="loading === monthlyPlan"
      @click="upgradePremium(monthlyPlan)"
    >
      {{
        $t('components.premium-form.monthly-price', {
          price: $n(4, 'currencyNoDecimal'),
        })
      }}
    </v-btn>
    <v-btn
      block
      color="primary"
      class="mt-2"
      :loading="loading === yearlyPlan"
      @click="upgradePremium(yearlyPlan)"
    >
      {{
        $t('components.premium-form.yearly-price', {
          yearPrice: $n(36, 'currencyNoDecimal'),
          monthPrice: $n(3, 'currencyNoDecimal'),
        })
      }}
    </v-btn>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      loading: null,

      monthlyPlan: process.env.VUE_APP_PREMIUM_PLAN_MONTHLY,
      yearlyPlan: process.env.VUE_APP_PREMIUM_PLAN_YEARLY,
    }
  },

  computed: {
    perks() {
      return [
        {
          text: this.$t('components.premium-form.repeatable-tasks'),
          icon: 'fa fa-sync',
        },
        {
          text: this.$t('components.premium-form.subtasks'),
          icon: 'fa fa-tasks',
        },
        {
          text: this.$t('components.premium-form.tags'),
          icon: 'fa fa-hashtag',
        },
        {
          text: this.$t('components.premium-form.image-upload'),
          icon: 'fa fa-images',
        },
      ]
    },
  },

  methods: {
    ...mapActions('stripe', ['createCheckout']),

    async upgradePremium(plan) {
      this.loading = plan

      try {
        const sessionId = await this.createCheckout(plan)

        const stripe = await loadStripe(
          process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
        )

        const { error } = await stripe.redirectToCheckout({
          sessionId,
        })

        if (error) throw new Error(error.message)
      } catch (error) {
        alert(error.message)
      } finally {
        this.loading = null
      }
    },
  },
}
</script>
