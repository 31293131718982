const { functions } = require('../firebase')

export default {
  sso: () => callMicroservice('onCall-sso'),
}

function callMicroservice(functionName, data) {
  const func = functions.httpsCallable(functionName)

  return func(data)
}
