<template>
  <v-container class="container--slim">
    <h1 class="text-h4 font-weight-bold mt-12 mb-8">
      {{ $t('views.subscription.title') }}
    </h1>

    <template v-if="isPremium">
      <template v-if="premium">
        <p>
          {{
            premium.autoRenews
              ? $t('views.subscription.subscription-renews-on', { premiumEnd })
              : $t('views.subscription.subscription-ends-on', { premiumEnd })
          }}
        </p>

        <v-btn
          color="primary"
          v-if="premium.platform === 'stripe' && premium.autoRenews"
          :loading="cancelling"
          @click="cancelSubscriptionClicked"
        >
          {{ $t('views.subscription.cancel-subscription') }}
        </v-btn>
        <p v-else-if="premium && premium.platform !== 'stripe'">
          Your subscription is managed by the app store. Please use your device
          to update your subscription.
        </p>
      </template>
    </template>
    <v-card v-else class="pa-4">
      <p>{{ $t('views.subscription.upgrade-text') }}</p>

      <PremiumForm />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import PremiumForm from '@/components/PremiumForm'

export default {
  components: {
    PremiumForm,
  },

  data() {
    return {
      cancelling: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['premium', 'isPremium']),

    premiumEnd() {
      return this.premium.periodEnd.toDate().toLocaleString()
    },
  },

  methods: {
    ...mapActions('stripe', ['cancelSubscription']),

    async cancelSubscriptionClicked() {
      if (
        confirm(
          this.$t('views.subscription.cancel-warning', {
            premiumEnd: this.premiumEnd,
          })
        )
      ) {
        this.cancelling = true

        try {
          await this.cancelSubscription(this.premium.id)
        } catch (error) {
          alert(error.message)
          this.cancelling = false
        }
      }
    },
  },
}
</script>
