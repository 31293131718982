<template>
  <div class="day js-day">
    <LogHeader
      :title="$t('components.day.daily-log')"
      :date="selectedDate"
      :level="$scopeMap.day"
      @addItemClicked="addNewEntry"
      @lastBtnClicked="
        setDateAndScope({
          date: selectedDate.minus({ day: 1 }),
          scope: $scopeMap.day,
        })
      "
      @nextBtnClicked="
        setDateAndScope({
          date: selectedDate.plus({ day: 1 }),
          scope: $scopeMap.day,
        })
      "
    />
    <v-divider class="mb-2"></v-divider>

    <EntryList
      :entries="displayEntries"
      :updateData="{

        ...getDTfromDateObject(this.dateObject, true),
      }"
      context="date"
      @addNewEntry="addNewEntry"
      :loading="loadingEntries"
      class="mb-10"
    />

    <div class="mb-10">
      <v-textarea
        v-for="promptEntry in promptsEntries"
        :key="promptEntry.id"
        :label="promptEntry.label"
        :value="promptEntry.value"
        rows="1"
        class="borderless-input"
        hide-details
        autocomplete="off"
        auto-grow
        @blur="addPromptNote($event, promptEntry.promptId, promptEntry.entryId)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getDateObject, getDTfromDateObject } from '@/helpers/convert-dates'
import { firebase } from '@/firebase'
import { EntriesDatabase } from '@/services/database'

import LogHeader from '@/components/LogHeader'
import EntryList from '@/components/EntryList'

export default {
  components: {
    LogHeader,
    EntryList,
  },

  data() {
    return {
      picker: new Date().toISOString().substr(0, 10),
      entries: [],
      listener: null,
      loadingEntries: false,
    }
  },

  computed: {
    ...mapState('app', ['selectedDate']),
    ...mapState('prompts', ['prompts']),

    dateObject() {
      return getDateObject(this.selectedDate, this.$scopeMap.day)
    },

    displayEntries() {
      return this.entries.filter(entry => !entry.promptId)
    },

    promptsEntries() {
      return this.prompts
        .filter(p => !p.archived && !p.deleting)
        .map(prompt => {
          const entry = this.entries.find(e => e.promptId === prompt.id)

          return {
            promptId: prompt.id,
            label: prompt.text,
            entryId: entry && entry.id,
            value: entry && entry.title,
          }
        })
    },
  },

  watch: {
    selectedDate: {
      handler: function(newVal, oldVal) {
        if (oldVal && newVal.toISODate() === oldVal.toISODate()) return

        this.getEntries(newVal)
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapActions('app', ['setDateAndScope']),
    ...mapActions('entries', ['newEntry', 'updateEntry']),

    getDTfromDateObject,

    disposeListener() {
      if (this.listener) this.listener()
    },

    getEntries(dt) {
      this.disposeListener()

      this.listener = EntriesDatabase.listenDayEntries(dt, data => {
        this.loadingEntries = false
        this.entries = data
      })
    },

    async addNewEntry(data = {}) {
      const newEntryDoc = await this.newEntry({
        ...getDTfromDateObject(this.dateObject, true),
        ...data,
      })

      this.$nextTick(() => {
        const el = document.querySelector(
          `.js-day .js-day-entry[data-id="${newEntryDoc.id}"] .js-title-input`
        )

        el.focus()
      })
    },

    addPromptNote(e, promptId, entryId) {
      const title = e.target.value

      if (entryId) {
        if (title) {
          this.updateEntry({
            id: entryId,
            data: {
              title,
            },
          })
        } else {
          this.updateEntry({
            id: entryId,
            data: {
              archived: true,
            },
          })
        }
      } else {
        if (!title) return
        this.addNewEntry({ title, promptId, type: 'note' })
      }
    },

    nowTimestamp() {
      return firebase.firestore.Timestamp.now()
    },
  },
}
</script>
