<template>
  <v-btn
    text
    class="month-entry"
    x-small
    color="primary"
    @click.stop="SET_SHOW_ENTRY(entry.id)"
  >
    {{ title }}
  </v-btn>
</template>

<script>
import { mapMutations } from 'vuex'
import EventBus from '@/eventBus'

export default {
  props: {
    entry: {
      required: true,
      type: Object,
    },
  },

  computed: {
    title() {
      let title = this.entry.title
      if (title.length > 30) title = title.substring(0, 30) + '…'
      return title
    },
  },

  methods: {
    ...mapMutations('entries', ['SET_SHOW_ENTRY']),
  },
}
</script>
